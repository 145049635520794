import { useMemo } from "react"
import { PlanDetailsColumns } from "Features/PlanDetails/PlanDetails.types"
import { searchTextOperator } from 'Common/MUITable/SearchText.operator'
import { Table as MUITable } from "Common/MUITable/MUITable"
import { TableContainer } from "@mui/material";
import { useStyles } from "./PlanDetails.styles"
import { GridCellParams, GridColDef } from "@mui/x-data-grid-premium";
import clsx from "clsx"


type RunRateTableProps = {
  data: any
}

export const RunRateTable = ({data}: RunRateTableProps) => {

  const classes = useStyles()

  const tableData = data.map((item: any, idx: number) => {
    return {
      ...item,
      id: idx
    }
  })

  const skuCodes = [
    ...new Set(
      data.map((item: PlanDetailsColumns) => {
        return item.productCode
      })
    ),
  ]

  const batchCodes = [
    ...new Set(
      data.map((item: PlanDetailsColumns) => {
        return item.batchGroupCode
      })
    ),
  ]

  const repl_types = [
    ...new Set(
      data.map((item: PlanDetailsColumns) => {
        return item.replenishmentType
      })
    ),
  ]

  const renderZero = (value: number) => {
    if (value === 0) {
      return <div></div>
    } else {
      return (
        <div>{value}</div>
      )
    }
  }


  const MUIColumns : GridColDef[] = useMemo(() => {
    if(!data){
      return []
    }

    return [
      {
        field: 'workcentreCode',
        headerName: 'Workcentre',
        type: 'string',
        flex: 2
      },
      {
        field: 'productCode',
        headerName: 'SKU group code',
        sortable: true,
        filterable: true,
        type: 'string',
        filterOperators: [
          {
            ...searchTextOperator[0],
            InputComponentProps: {
              data: skuCodes,
              label: 'Sku codes',
            },
          },
        ],
        flex: 1
      },
      {
        field: 'description',
        headerName: 'Description',
        renderCell: (params: any) => {
          const { description } = params.row
          if (description === 'nan') {
            return <div></div>
          } else {
            return <div>{description}</div>
          }
        },
        type: 'string',
        flex: 2
      },
      {
        field: 'batchGroupCode',
        headerName: 'Batch group code',
        type: 'string',
        filterOperators: [
          {
            ...searchTextOperator[0],
            InputComponentProps: {
              data: batchCodes,
              label: 'search',
            },
          },
        ],
      },
      {
        field: 'replenishmentType',
        headerName: 'Replenishment Type',
        cellClassName: (params: GridCellParams) => {
          return clsx('table', {
            foq: params.row.replenishmentType === 'FOQ'
          })
        },
        filterOperators: [
          {
            ...searchTextOperator[0],
            InputComponentProps: {
              data: repl_types,
              label: 'search',
            },
          },
        ],
        type: 'string',
        flex: 2
      },
      {
        field: 'minimumOrderQuantity',
        headerName: 'MOQ',
        type: 'number',
        flex: 1
      },
      {
        field: 'minimumOrderIncrement',
        headerName: 'MOI',
        type: 'number',
        flex: 1
      },
      {
        field: 'cycle',
        headerName: 'Cycle',
        type: 'number',
      },
      {
        field: 'week_1',
        headerName: 'Week 1',
        cellClassName: (params: GridCellParams) => {
          if (params.rowNode.type === 'pinnedRow'){
            return classes.aggregatedCell
          }
          return clsx('table', {
            zero: params.value !== 0,
          })
        },
        renderCell: (params: GridCellParams) => {
          if (params.rowNode.type === 'pinnedRow'){
            const value = params.value as number
            return <strong>{value.toFixed(2)}</strong>
          }
          return renderZero(params.value as number)
        },
        type: 'number'
      },
      {
        field: 'week_2',
        headerName: 'Week 2',
        cellClassName: (params: GridCellParams) => {
          if (params.rowNode.type === 'pinnedRow'){
            return classes.aggregatedCell
          }
          return clsx('table', {
            zero: params.value !== 0,
          })
        },
        renderCell: (params: GridCellParams) => {
          if (params.rowNode.type === 'pinnedRow'){
            const value = params.value as number
            return <strong>{value.toFixed(2)}</strong>
          }
          return renderZero(params.value as number)
        },
        type: 'number'
      },
      {
        field: 'week_3',
        headerName: 'Week 3',
        cellClassName: (params: GridCellParams) => {
          if (params.rowNode.type === 'pinnedRow'){
            return classes.aggregatedCell
          }
          return clsx('table', {
            zero: params.value !== 0,
          })
        },
        renderCell: (params: GridCellParams) => {
          if (params.rowNode.type === 'pinnedRow'){
            const value = params.value as number
            return <strong>{value.toFixed(2)}</strong>
          }
          return renderZero(params.value as number)
        },
        type: 'number'
      },
      {
        field: 'week_4',
        headerName: 'Week 4',
        cellClassName: (params: GridCellParams) => {
          if (params.rowNode.type === 'pinnedRow'){
            return classes.aggregatedCell
          }
          return clsx('table', {
            zero: params.value !== 0,
          })
        },
        renderCell: (params: GridCellParams) => {
          if (params.rowNode.type === 'pinnedRow'){
            const value = params.value as number
            return <strong>{value.toFixed(2)}</strong>
          }
          return renderZero(params.value as number)
        },
        type: 'number'
      },
      {
        field: 'week_5',
        headerName: 'Week 5',
        cellClassName: (params: GridCellParams) => {
          if (params.rowNode.type === 'pinnedRow'){
            return classes.aggregatedCell
          }
          return clsx('table', {
            zero: params.value !== 0,
          })
        },
        renderCell: (params: GridCellParams) => {
          if (params.rowNode.type === 'pinnedRow'){
            const value = params.value as number
            return <strong>{value.toFixed(2)}</strong>
          }
          return renderZero(params.value as number)
        },
        type: 'number'
      },
      {
        field: 'week_6',
        headerName: 'Week 6',
        cellClassName: (params: GridCellParams) => {
          if (params.rowNode.type === 'pinnedRow'){
            return classes.aggregatedCell
          }
          return clsx('table', {
            zero: params.value !== 0,
          })
        },
        renderCell: (params: GridCellParams) => {
          if (params.rowNode.type === 'pinnedRow'){
            const value = params.value as number
            return <strong>{value.toFixed(2)}</strong>
          }
          return renderZero(params.value as number)
        },
        type: 'number'
      },
      {
        field: 'week_7',
        headerName: 'Week 7',
        cellClassName: (params: GridCellParams) => {
          if (params.rowNode.type === 'pinnedRow'){
            return classes.aggregatedCell
          }
          return clsx('table', {
            zero: params.value !== 0,
          })
        },
        renderCell: (params: GridCellParams) => {
          if (params.rowNode.type === 'pinnedRow'){
            const value = params.value as number
            return <strong>{value.toFixed(2)}</strong>
          }
          return renderZero(params.value as number)
        },
        type: 'number'
      },
      {
        field: 'week_8',
        headerName: 'Week 8',
        cellClassName: (params: GridCellParams) => {
          if (params.rowNode.type === 'pinnedRow'){
            return classes.aggregatedCell
          }
          return clsx('table', {
            zero: params.value !== 0,
          })
        },
        renderCell: (params: GridCellParams) => {
          if (params.rowNode.type === 'pinnedRow'){
            const value = params.value as number
            return <strong>{value.toFixed(2)}</strong>
          }
          return renderZero(params.value as number)
        },
        type: 'number'
      },
    ]
  }, [data])


  return (
      <TableContainer className={classes.runTimeTable}>
        <MUITable
          rows={tableData}
          columns={MUIColumns}
          showCellVerticalBorder
          showColumnVerticalBorder
          initialState={{
            pagination: { paginationModel: { pageSize: 20 } },
            aggregation: {
              model: {
                week_1: 'sum',
                week_2: 'sum',
                week_3: 'sum',
                week_4: 'sum',
                week_5: 'sum',
                week_6: 'sum',
                week_7: 'sum',
                week_8: 'sum'
              }
            }
          }}
          pagination
          pageSizeOptions={[20, 30, 50]}
          disableRowSelectionOnClick
          sx={{
            '& .MuiDataGrid-virtualScroller': {
              overflowY: 'hidden', // Hide vertical scrollbar
            },
          }}
        />
      </TableContainer>
  )
}