import { IconButton, Tooltip } from '@material-ui/core'
import { Card, CardContent, Paper } from '@material-ui/core'
import { Page } from 'Common/Page/Page'
import { useState, useEffect } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { PageHeader, Button } from 'supplyvue-ui'
import { PlanDetailsOptimizationDropDown } from './PlanDetailsDropdown'
import {
  PlanDetailsColumns,
  PlanDetailsParamTypes,
  UpdatedRow,
} from './PlanDetails.types'
import { useBreadcrumbsData } from 'Common/Breadcrumbs/Breadcrumbs.data'
import { optimizationTypes } from './PlanDetails.constants'
import {
  usePlanDetails,
  useDownloadPlanDetails,
  useSaveTemplateChanges,
} from './PlanDetails.data'
import { PlanDetailsTable } from './PlanDetails.table'
import GetAppIcon from '@material-ui/icons/GetApp'
import { useStyles } from './PlanDetails.styles'
import { Loader } from 'Common/Loader/Loader'
import toast from 'react-hot-toast'
import { TableTabs } from "./PlanDetailsTabs"
import { RunRateTable } from "./RunRateTable"
import { SequenceTable } from "./SequenceTable"

export const PlanDetailsPage = () => {
  const classes = useStyles()
  const { search } = useLocation()
  const history = useHistory()
  const query = new URLSearchParams(search)
  const selectedOptimization = query.get('optimization') ?? 'constrained'
  const [optimization, setOptimization] = useState(selectedOptimization)

  const {
    projectId,
    scenarioId,
    sourceSiteId,
    workcentreId,
    planTemplateId,
    snapshotId,
  } = useParams<PlanDetailsParamTypes>()

  useBreadcrumbsData({
    project_id: projectId,
    work_center_id: workcentreId,
    scenario_id: scenarioId,
    plan_template_id: planTemplateId,
    base_source_site_id: sourceSiteId,
  })

  const { data, isLoading } = usePlanDetails(
    planTemplateId,
    sourceSiteId,
    workcentreId,
    optimization
  )

  const [tab, setTab] = useState<number>(0)

  const [tableData, setTableData] = useState<PlanDetailsColumns[] | undefined>(
    undefined
  )

  const [runRateData, setRunRateData] = useState<any>(null)
  const [sequenceData, setSequenceData] = useState<any>(null)

  const [updatedRow, setUpdatedRow] = useState<UpdatedRow | undefined>(
    undefined
  )

  useEffect(() => {
    if (data) {
      setTableData(data?.details)
      setRunRateData(data?.runRateTable)
      setSequenceData(data?.sequenceTable)
    }
  }, data)

  useEffect(() => {
    const timer = setTimeout(() => {
      setUpdatedRow(undefined)
    }, 1000)

    return () => clearTimeout(timer)
  }, [updatedRow])
  const { refetch: downloadPlanTemplate } = useDownloadPlanDetails(
    planTemplateId,
    sourceSiteId,
    workcentreId,
    optimization
  )

  const handleOptimizationChange = (optimization: string) => {
    history.push(
      `/projects/${projectId}/snapshots/${snapshotId}/summary/${planTemplateId}/scenarios/${scenarioId}/${sourceSiteId}/${workcentreId}/plan-details?optimization=${optimization}`
    )
    setOptimization(optimization)
  }

  const onSuccess = () => {
    toast.success('Update succesful')
  }

  const onError = () => {
    toast.error('Update failed')
  }

  const {
    mutate: saveChanges,
    isLoading: savingChanges,
  } = useSaveTemplateChanges(onSuccess, onError)

  const handleSaveChanges = () => {
    if (tableData) {

      saveChanges({
        newTemplate: tableData,
        planTemplateId: planTemplateId,
        workcentreId: workcentreId,
        sourceSiteId: sourceSiteId,
        method: optimization,
      })
    }
  }

  const handleDownload = () => {
    downloadPlanTemplate()
  }

  return (
    <Page>
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: 1200 }}>
        <PageHeader
          title="Template details"
          rightElement={
            <div style={{ display: 'flex' }}>
              <PlanDetailsOptimizationDropDown
                label="Optimisation type"
                menuItems={optimizationTypes}
                value={optimization}
                handleOptimizationChange={handleOptimizationChange}
              />
              <div className={classes.downloadWrapper}>
                <Tooltip title="Download CSV">
                  <IconButton
                    className={
                      !data?.length
                        ? classes.iconButtonDisabled
                        : classes.iconButton
                    }
                    color="primary"
                    aria-label="Download"
                    onClick={handleDownload}
                    disabled={!data?.length}
                  >
                    <GetAppIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          }
        />
        <div>
          {isLoading || !tableData ? (
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'centre:', alignItems: 'center', height: '100%' }}>
                <Loader />
              </div>
          ) : (
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%', maxHeight: 800 }}>
              <TableTabs tab={tab} setTab={setTab} />
              <Paper>
                {tab === 0 ?
                  (<PlanDetailsTable
                    data={tableData}
                    setData={setTableData}
                    updatedRow={updatedRow}
                    setUpdatedRow={setUpdatedRow}
                  />) :
                  tab === 1 ? (
                    <RunRateTable data={runRateData} />
                  ) : (
                    <SequenceTable data={sequenceData} />
                  )
                }

              </Paper>
              <div className={classes.saveButton}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleSaveChanges}
                  disabled={savingChanges}
                >
                  Save Changes
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </Page>
  )
}
