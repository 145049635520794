import { Box, CircularProgress, Typography, Divider } from '@material-ui/core'
import { isEqual } from 'lodash'
import { useEffect, useState } from 'react'
import { Button, DropDown, Input, Modal } from 'supplyvue-ui'
import { useStyles } from './ProductSettingsForm.styles'
import {
  ProductSettingsFormBulkUpdateViewProps,
  ProductSettingsFormDataKeysType,
} from './ProductSettingsForm.types'

export const INITIAL_STATE = {
  batchGroupingCode: '',
  salesRate: '',
  runRate: '',
  minimumOrderQuantity: '',
  minimumOrderIncrement: '',
  minimumCycle: '',
  maximumCycle: '',
  salesWeeksPerMOQ: '',
  replenishmentTypeId: '',
  productionUnitOfMeasure: '',
  runRateUnitOfMeasure: '',
  replenishmentType: '',
  include: '',
  salesRateActionType: '0',
  runRateActionType: '0',
  minimumOrderQuantityActionType: '0',
  minimumOrderIncrementActionType: '0',
  startWeek: ''
}

const EXCLUDED_KEYS = {
  include: '',
  salesRateActionType: '0',
  runRateActionType: '0',
  minimumOrderQuantityActionType: '0',
  minimumOrderIncrementActionType: '0',
}

const includeOptions = [
  { key: 'Yes', value: true },
  { key: 'No', value: false },
]

const actionOptions = [
  { key: 'Set value to', value: '0' },
  { key: 'Increase by percentage', value: '1' },
  { key: 'Decrease by percentage', value: '2' },
]

export const ProductSettingsFormBulkUpdateView = ({
  isModalOpen,
  handleModalClose,
  replenishmentTypes,
  maximumCycleTypes,
  minimumCycleTypes,
  batchGroupingCodeOptions,
  handleSaveClick,
  isUpdating,
  data,
  setData,
}: ProductSettingsFormBulkUpdateViewProps) => {
  const classes = useStyles()

  const [isIncludeSelected, setIsIncludeSelected] = useState(false)
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name
    const value = event.target.value
    if (name !== 'batchGroupingCode') {
      const VALID_NUMBER_PATTERN = /^[+]?((\.\d{0,5})|(\d+(\.\d{0,5})?)|(\d+\.))$/
      if (event.target.value === '' || value.match(VALID_NUMBER_PATTERN)) {
        setData({
          ...data,
          [name]: value,
        })
      }
    } else {
      setData({
        ...data,
        [name]: value,
      })
    }
  }

  const handleDropdownChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const name = event.target.name as string
    if (name === 'replenishmentTypeId') {
      const replenishmentType = replenishmentTypes.filter(
        (type) => type.value === event.target.value
      )
      setData({
        ...data,
        replenishmentType:
          replenishmentType.length > 0 ? replenishmentType[0].key : '',
        [name]: event.target.value as string,
      })
    } else if (name === 'include' && event.target.value !== '') {
      setData({
        ...data,
        [name]: event.target.value ? true : false,
      })
    } else {
      setData({
        ...data,
        [name]: event.target.value as string,
      })
    }
  }

  const handleSaveButtonClick = async () => {
    handleSaveClick(data)
    //handleModalClose()
  }
  const handleClearButtonClick = () => {
    setData(INITIAL_STATE)
  }

  useEffect(() => {
    if (data.include !== '') {
      setIsIncludeSelected(true)
    } else {
      setIsIncludeSelected(false)
    }
  }, [data])

  const isDataUpdated = () => {
    for (const key in data) {
      if (!(key in EXCLUDED_KEYS)) {
        if (data[key as ProductSettingsFormDataKeysType] !== '') {
          return true
        }
      }
    }
    return false
  }

  return (
    <Box>
      <Box>
        <Modal
          disableBackdropClick
          open={isModalOpen}
          handleClose={handleModalClose}
          header={'Edit Selection'}
          body={
            <Box mt={2} mb={2} display="flex" flexDirection="column">
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Box display="flex" flexDirection="column">
                  <Box width="15rem">
                    <Typography variant="h4">Metric</Typography>
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column">
                  <Box width="15rem">
                    <Typography variant="h4">Action</Typography>
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column">
                  <Box width="15rem">
                    <Typography variant="h4">Value</Typography>
                  </Box>
                </Box>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Divider flexItem className={classes.divider} />
              </Box>
              <Box
                mt={2}
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box display="flex" flexDirection="column">
                  <Box width="15rem">
                    <Typography variant="h4">Batch group code</Typography>
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column">
                  <Box width="15rem">
                    <Input
                      fullWidth={true}
                      ariaLabel="Set value to"
                      value="Set value to"
                      name="setValueTo"
                      disabled={true}
                    />
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column" ml={3}>
                  <Box width="15rem">
                    <DropDown
                      fullWidth={true}
                      label="Batch group code"
                      name="batchGroupingCode"
                      defaultLabel="None"
                      menuItems={batchGroupingCodeOptions}
                      value={data.batchGroupingCode}
                      onChange={handleDropdownChange}
                      disabled={isIncludeSelected}
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                mt={2}
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box display="flex" flexDirection="column">
                  <Box width="15rem">
                    <Typography variant="h4">Replenishment type</Typography>
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column">
                  <Box width="15rem">
                    <Input
                      fullWidth={true}
                      ariaLabel="Set value to"
                      value="Set value to"
                      name="setValueTo"
                      disabled={true}
                    />
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column" ml={3}>
                  <Box width="15rem">
                    <DropDown
                      fullWidth={true}
                      label="Replenishment type"
                      name="replenishmentTypeId"
                      defaultLabel="None"
                      menuItems={replenishmentTypes}
                      value={data.replenishmentTypeId}
                      onChange={handleDropdownChange}
                      disabled={isIncludeSelected}
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                mt={2}
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box display="flex" flexDirection="column">
                  <Box width="15rem">
                    <Typography variant="h4">Sales rate</Typography>
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column">
                  <Box width="15rem">
                    <DropDown
                      fullWidth={true}
                      label="SelectSalesRateAction"
                      name="salesRateActionType"
                      menuItems={actionOptions}
                      value={data.salesRateActionType}
                      onChange={handleDropdownChange}
                      disabled={isIncludeSelected}
                    />
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column" ml={3}>
                  <Box width="15rem">
                    <Input
                      fullWidth={true}
                      ariaLabel="Sales rate"
                      value={data.salesRate}
                      onChange={handleInputChange}
                      name="salesRate"
                      disabled={isIncludeSelected}
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                mt={2}
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box display="flex" flexDirection="column">
                  <Box width="15rem">
                    <Typography variant="h4">Run rate</Typography>
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column">
                  <Box width="15rem">
                    <DropDown
                      fullWidth={true}
                      label="SelectRunRateAction"
                      name="runRateActionType"
                      menuItems={actionOptions}
                      value={data.runRateActionType}
                      onChange={handleDropdownChange}
                      disabled={isIncludeSelected}
                    />
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column" ml={3}>
                  <Box width="15rem">
                    <Input
                      fullWidth={true}
                      ariaLabel="Run rate"
                      value={data.runRate}
                      onChange={handleInputChange}
                      name="runRate"
                      disabled={isIncludeSelected}
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                mt={2}
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box display="flex" flexDirection="column">
                  <Box width="15rem">
                    <Typography variant="h4">Minimum order quantity</Typography>
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column">
                  <Box width="15rem">
                    <DropDown
                      fullWidth={true}
                      label="SelectMinimumOrderQuantityAction"
                      name="minimumOrderQuantityActionType"
                      menuItems={actionOptions}
                      value={data.minimumOrderQuantityActionType}
                      onChange={handleDropdownChange}
                      disabled={isIncludeSelected}
                    />
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column" ml={3}>
                  <Box width="15rem">
                    <Input
                      fullWidth={true}
                      ariaLabel="Minimum order quantity"
                      value={data.minimumOrderQuantity}
                      onChange={handleInputChange}
                      name="minimumOrderQuantity"
                      disabled={isIncludeSelected}
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                mt={2}
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box display="flex" flexDirection="column">
                  <Box width="15rem">
                    <Typography variant="h4">
                      Minimum order increment
                    </Typography>
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column">
                  <Box width="15rem">
                    <DropDown
                      fullWidth={true}
                      label="SelectMinimumOrderIncrementAction"
                      name="minimumOrderIncrementActionType"
                      menuItems={actionOptions}
                      value={data.minimumOrderIncrementActionType}
                      onChange={handleDropdownChange}
                      disabled={isIncludeSelected}
                    />
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column" ml={3}>
                  <Box width="15rem">
                    <Input
                      fullWidth={true}
                      ariaLabel="Minimum order increment"
                      value={data.minimumOrderIncrement}
                      onChange={handleInputChange}
                      name="minimumOrderIncrement"
                      disabled={isIncludeSelected}
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                mt={2}
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box display="flex" flexDirection="column">
                  <Box width="15rem">
                    <Typography variant="h4">Minimum cycle</Typography>
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column">
                  <Box width="15rem">
                    <Input
                      fullWidth={true}
                      ariaLabel="Set value to"
                      value="Set value to"
                      name="setValueTo"
                      disabled={true}
                    />
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column" ml={3}>
                  <Box width="15rem">
                    <DropDown
                      fullWidth={true}
                      label="Minimum cycle"
                      name="minimumCycle"
                      defaultLabel="None"
                      menuItems={minimumCycleTypes}
                      value={data.minimumCycle}
                      onChange={handleDropdownChange}
                      disabled={isIncludeSelected}
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                mt={2}
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box display="flex" flexDirection="column">
                  <Box width="15rem">
                    <Typography variant="h4">Maximum cycle</Typography>
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column">
                  <Box width="15rem">
                    <Input
                      fullWidth={true}
                      ariaLabel="Set value to"
                      value="Set value to"
                      name="setValueTo"
                      disabled={true}
                    />
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column" ml={3}>
                  <Box width="15rem">
                    <DropDown
                      fullWidth={true}
                      label="Maximum cycle"
                      name="maximumCycle"
                      defaultLabel="None"
                      menuItems={maximumCycleTypes}
                      value={data.maximumCycle}
                      onChange={handleDropdownChange}
                      disabled={isIncludeSelected}
                      defaultValue=""
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                mt={2}
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box display="flex" flexDirection="column">
                  <Box width="15rem">
                    <Typography variant="h4">Include</Typography>
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column">
                  <Box width="15rem">
                    <Input
                      fullWidth={true}
                      ariaLabel="Set value to"
                      value="Set value to"
                      name="setValueTo"
                      disabled={true}
                    />
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column" ml={3}>
                  <Box width="15rem">
                    <DropDown
                      fullWidth={true}
                      label="Include"
                      name="include"
                      menuItems={includeOptions as any}
                      defaultLabel="None"
                      defaultValue=""
                      value={data.include as any}
                      onChange={(event) => {
                        handleDropdownChange(event)
                      }}
                      disabled={isDataUpdated()}
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                mt={2}
                justifyContent="space-between"
                alignItems="center"
              >
                <Box display="flex" flexDirection="column">
                  <Box width="15rem">
                    <Typography variant="h4">Start Week</Typography>
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column">
                  <Box width="15rem">
                    <Input
                      fullWidth={true}
                      ariaLabel="Set value to"
                      value="Set value to"
                      name="setValueTo"
                      disabled={true}
                    />
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column" ml={3}>
                  <Box width="15rem">
                    <Input
                      fullWidth={true}
                      ariaLabel="Start week"
                      value={data.startWeek}
                      onChange={handleInputChange}
                      name="startWeek"
                      disabled={isIncludeSelected}
                    />
                  </Box>
                </Box>

              </Box>
              <Box
                display="flex"
                flexDirection="row"
                mt={4}
                justifyContent="space-between"
              >
                <Box display="flex" width="100px" justifyContent="flex-end">
                  {/*dummy block*/}
                </Box>
              </Box>
              <Box display="flex" alignSelf="flex-end" mt="10px">
                <Button
                  className={classes.button}
                  color="primary"
                  variant="contained"
                  onClick={handleClearButtonClick}
                  disabled={isEqual(INITIAL_STATE, data)}
                >
                  Clear
                </Button>
                <Button
                  className={classes.button}
                  color="primary"
                  variant="contained"
                  onClick={handleSaveButtonClick}
                  disabled={
                    (isUpdating || !isDataUpdated()) && !isIncludeSelected
                  }
                >
                  {isUpdating && (
                    <CircularProgress
                      color="inherit"
                      size={16}
                      className={classes.progress}
                    />
                  )}
                  Apply changes
                </Button>
              </Box>
            </Box>
          }
          className={classes.modal}
        />
      </Box>
    </Box>
  )
}
