import { useMutation, useQuery, useQueryClient } from 'react-query'
import { request } from 'Features/Auth/axios-client'
import { format } from 'date-fns'
import {
  UpdateChangesPayload,
} from 'Features/PlanDetails/PlanDetails.types'

const fetchPlanDetails = async (
  planTemplateId: string,
  sourceSiteKey: string,
  workCentreKey: string,
  optimization: string
) => {
  const { data } = await request({
    url: `/plan-templates/${planTemplateId}/details?source_site_key=${sourceSiteKey}&workcentre_key=${workCentreKey}&optimization=${optimization}`,
  })
  return data
}

export const usePlanDetails = (
  planTemplateId: string,
  sourceSiteKey: string,
  workCentreKey: string,
  optimization: string
) => {
  return useQuery(
    [
      'plan-details',
      planTemplateId,
      sourceSiteKey,
      workCentreKey,
      optimization,
    ],
    () =>
      fetchPlanDetails(
        planTemplateId,
        sourceSiteKey,
        workCentreKey,
        optimization
      ),
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      select: (response) => response.data,
    }
  )
}

const downloadPlanDetails = async (
  planTemplateId: string,
  sourceSiteKey: string,
  workCentreKey: string,
  optimization: string
) => {
  const download = true
  const { data } = await request({
    url: `/plan-templates/${planTemplateId}/details?source_site_key=${sourceSiteKey}&workcentre_key=${workCentreKey}&optimization=${optimization}&download=${download}`,
  })

  const fileName = `Plan_details__${format(new Date(), 'yyyyMMdd')}`
  const url = window.URL.createObjectURL(new Blob([data]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', `${fileName}.csv`)
  document.body.appendChild(link)
  link.click()
  return data
}

export const useDownloadPlanDetails = (
  planTemplateId: string,
  sourceSiteKey: string,
  workCentreKey: string,
  optimization: string
) => {
  return useQuery(
    [
      'download-plan-details',
      planTemplateId,
      sourceSiteKey,
      workCentreKey,
      optimization,
    ],
    () =>
      downloadPlanDetails(
        planTemplateId,
        sourceSiteKey,
        workCentreKey,
        optimization
      ),
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      select: (data) => data.data,
      enabled: false,
    }
  )
}
const saveTemplateChanges = async ({
  newTemplate,
  planTemplateId,
  workcentreId,
  sourceSiteId,
  method,
}: UpdateChangesPayload) => {
  const { data } = await request({
    url: `/plan-templates/update-template`,
    method: 'put',
    data: {
      template: newTemplate,
      planTemplateId: planTemplateId,
      workcentreId: workcentreId,
      sourceSiteId: sourceSiteId,
      method: method,
    },
  })
  return data
}

export const useSaveTemplateChanges = (
  onSuccess: () => void,
  onError: () => void
) => {
  return useMutation(saveTemplateChanges, {
    onSuccess: () => {
      onSuccess()
    },
    onError: () => {
      onError()
    },
  })
}
