import { Dispatch, SetStateAction } from "react";
import { Tabs, Tab } from '@material-ui/core'
import { useStyles } from './PlanDetails.styles'


type TableTabsProps = {
  tab: number
  setTab: Dispatch<SetStateAction<number>>
}

export const TableTabs = ({
  tab, setTab
                          }: TableTabsProps
) => {
  const classes = useStyles()

  return (
    <div>
      <Tabs
        value={tab}
        classes={{ indicator: classes.tabsIndicator}}
        className={classes.tabContainer}
        onChange={(event, value) => setTab(value)}
        variant="scrollable"
      >
        <Tab
          label="Details"
          className={classes.tab}
        />
        <Tab
          label="Run Time"
          className={classes.tab}
        />
        <Tab
          label="Sequence"
          className={classes.tab}
        />

      </Tabs>
    </div>
  )









}