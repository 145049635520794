import { useMemo } from 'react'
import { searchTextOperator } from 'Common/MUITable/SearchText.operator'
import { ProductSettingsTableProps } from './ProductSettings.types'
import { ProductSettings } from 'Features/PlanTemplate/StepCard/StepCard.types'
import { UnitOfMeasureLabel } from './UnitOfMeasureLabel/UnitOfMeasureLabel.view'
import { Typography, Tooltip } from '@material-ui/core'
import { get, has } from 'lodash'
import { BlankTable as MUITable } from 'Common/MUITable/MUITable'
import { GridColDefPremium } from '@mui/x-data-grid-premium/typeOverloads'
import { GridRowSelectionModel } from '@mui/x-data-grid-premium'
import { Loader } from 'Common/Loader/Loader'
import { TaskAlt, PublishedWithChanges, RemoveCircleOutline } from "@mui/icons-material"
import { useStyles } from "./ProductSettings.styles"

export const ProductSettingsTable = ({
  data,
  setSelectedRows,
  unitOfMeasure,
  adjustedValues,
}: ProductSettingsTableProps) => {

  const classes = useStyles()

  const getTableHeight = () => {
    const MIN_HEIGHT = 640
    const availableHeight = window.outerHeight - 240
    return availableHeight > MIN_HEIGHT ? availableHeight : MIN_HEIGHT
  }


  if (!data) {
    return <Loader />
  }

  const skuCodes = [
    ...new Set(
      data.map((item: ProductSettings) => {
        return item.productCode
      })
    ),
  ]

  const descriptions = [
    ...new Set(
      data.map((item: ProductSettings) => {
        return item.description
      })
    )
  ]

  const tableData = data.map((item: ProductSettings, idx) => {
    return {
      ...item,
      id: idx,
    }
  })

  type RenderStatusProps = {
    salesRate: number
    currentCycleTime: number
    include: boolean
  }

  const RenderStatus = ({salesRate, currentCycleTime, include} :RenderStatusProps) => {

    if (!include) {
      return <div></div>
    }

    if (salesRate > 0) {
      if (currentCycleTime !== -1) {
        return (
          <Tooltip title="Unchanged">
            <TaskAlt sx={{color: 'green'}}></TaskAlt>
          </Tooltip>
        )
      } else {
        return (
          <Tooltip title="New Sku">
            <PublishedWithChanges sx={{color: 'orange'}}></PublishedWithChanges>
          </Tooltip>
        )
      }
    } else {
      if (currentCycleTime === -1) {
        return (
          <Tooltip title="Unchanged">
            <TaskAlt sx={{color: 'green'}}></TaskAlt>
          </Tooltip>
        )
      } else {
        return (
          <Tooltip title="Removed from template">
            <RemoveCircleOutline sx={{color: 'red'}}/>
          </Tooltip>
        )
      }
    }
  }

  const renderValue = (value: number) => {
    if (value != -1) {
      return <div>{value}</div>
    } else {
      return <div></div>
    }
  }

  const MUIColumns: GridColDefPremium[] = useMemo(() => {
    if (!data) {
      return []
    }

    return [
      {
        field: 'productCode',
        headerName: 'SKU group code',
        filterOperators: [
          {
            ...searchTextOperator[0],
            InputComponentProps: {
              data: skuCodes,
              label: 'sku Code'
            }
          }
        ],
        renderCell: (params: any) => {
          const { productCode, description, salesRate, currentCycleTime, include } = params.row

          return (
            <div style={{display: 'flex', flexDirection:'column', width:'100%'}}>
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 2}}>
                <Typography variant='h4'>{description}</Typography>
                <RenderStatus salesRate={salesRate} currentCycleTime={currentCycleTime} include={include}/>
              </div>
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start'}}>
                <Typography variant='subtitle1'>{productCode}</Typography>
              </div>
            </div>
          )

        },
        width: 450
      },
      {
        field: 'primaryWorkCentreCode',
        headerName: 'Primary Work Centre',
        type: 'string',
        flex: 1
      },
      {
        field: 'segment',
        headerName: 'Segment',
        type: 'string',
        flex: 1
      },
      {
        field: 'productFormat',
        headerName: 'SKU group format',
        type: 'string',
        flex: 1
      },
      {
        field: 'batchGroupingCode',
        headerName: 'Batch group code',
        type: 'string',
        flex: 1
      },
      {
        field: 'replenishmentType',
        headerName: 'Replenishment type',
        type: 'string',
        renderCell: (params: any) => {
          const { skuGroupKey, replenishmentType } = params.row
          const isAdjusted = has(
            get(adjustedValues, `${skuGroupKey}`) ?? {},
            'replenishmentType'
          )

          return (
            <Typography color={isAdjusted ? 'error' : 'inherit'}>
              {replenishmentType ?? '-'}
            </Typography>
          )
        },
        flex: 1
      },
      {
        field: 'salesRate',
        headerName: 'Sales rate',
        type: 'number',
      },
      {
        field: 'runRate',
        headerName: 'Run rate',
        type: 'number',
        renderCell: (params: any) => {
          const {runRate, runRateUnitOfMeasure} = params?.row
          return (
            <div style={{display: 'flex', flexDirection: 'row', width: '90%', justifyContent: 'flex-start', gap: 4, alignItems: 'center'}}>
             <Typography variant='body1' component='span'>{runRate.toFixed(1).toString()}</Typography>
              <Typography variant='body2' component='span'>{runRateUnitOfMeasure}</Typography>
            </div>
          )

        }
      },
      {
        field: 'minimumOrderIncrement',
        headerName: 'MOI',
        type: 'number',
      },
      {
        field: 'minimumOrderQuantity',
        headerName: 'MOQ',
        type: 'number',
      },
      {
        field: 'productionUnitOfMeasure',
        headerName: 'UoM',
        type: 'string',
        renderCell: (params: any) => (
          <UnitOfMeasureLabel
            id={params.row.productionUnitOfMeasureId}
            label={params.row.productionUnitOfMeasure}
            unitOfMeasure={unitOfMeasure}
          />
        ),
      },
      // HIDING THESE COLUMNS FOR NOW
      //{
        //field: 'currentCycleTime',
        //headerName: 'Current Cycle',
        //type: 'number',
        //renderCell: (params: any) => {
          //const { currentCycleTime } = params.row
          //return renderValue(currentCycleTime)
        //}
      //},
      //{
        //field: 'currentStartWeek',
        //headerName: 'Current Start Week',
        //type: 'number',
        //renderCell: (params: any) => {
          //const { currentStartWeek } = params.row
          //return renderValue(currentStartWeek)
        //}
      //},
      //{
        //field: 'fixedStartWeek',
        //headerName: 'Fixed Start Week',
        //type: 'number',
        //renderCell: (params: any) => {
          //const { fixedStartWeek } = params.row
          //return renderValue(fixedStartWeek)
        //}
      //},
      {
        field: 'minimumCycle',
        headerName: 'Min cycle',
        type: 'number',
        renderCell: (params: any) => {
          const { skuGroupKey, minimumCycle } = params.row
          const isAdjusted = has(
            get(adjustedValues, `${skuGroupKey}`) ?? {},
            'minimumCycle'
          )

          return (
            <Typography color={isAdjusted ? 'error' : 'inherit'}>
              {minimumCycle ?? '-'}
            </Typography>
          )
        },
      },
      {
        field: 'maximumCycle',
        headerName: 'Max cycle',
        type: 'number',
        renderCell: (params: any) => {
          const { skuGroupKey, maximumCycle } = params.row
          const isAdjusted = has(
            get(adjustedValues, `${skuGroupKey}`) ?? {},
            'maximumCycle'
          )

          return (
            <Typography color={isAdjusted ? 'error' : 'inherit'}>
              {maximumCycle ?? '-'}
            </Typography>
          )
        },
      },
      {
        field: 'include',
        headerName: 'Include',
        type: 'boolean',
      },
    ]
  }, [data, adjustedValues])

  const getSelectedRows = (ids: GridRowSelectionModel) => {
    const selectedIds = new Set(ids)
    setSelectedRows(tableData.filter((row) => selectedIds.has(row.id)))
  }
  return (
    <div style={{ height: getTableHeight(), width: '100%', maxWidth: '120%'}}>
      <MUITable
        rows={tableData}
        checkboxSelection
        columns={MUIColumns}
        getRowClassName={(params: any) => !params.row.include ? classes.inactiveRow : ''}
        showCellVerticalBorder
        showColumnVerticalBorder
        initialState={{
          pagination: { paginationModel: { pageSize: 20 } },
        }}
        onRowSelectionModelChange={(ids) => getSelectedRows(ids)}
        pagination
        experimentalFeatures={{ lazyLoading: true }}
      />
    </div>
  )
}
